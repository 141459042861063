/* You can add global styles to this file, and also import other style files */

@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap');

/*Custom styles*/
body{
    background-color: #F5F2FA;
    font-family: Inter, 'Arial Narrow Bold', sans-serif;
    -webkit-tap-highlight-color: transparent;
}

p{
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: #3B3D3D;
}

hr{
    border: 0px;
    height: 1px;
    background-color: #FFFFFF;
}

h1, h2, h3, h4, h5{
    background-image: linear-gradient(45deg, #8D61E1, #602BC6);
    background-size: 100%;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-text-fill-color: transparent;
}

h1{
  line-height: 42px;
  font-size: 36px;
  font-weight: 700;
}

@media screen and (min-width: 1024px) {
  h1 {
    line-height: 40px;
    font-size: 38px;
  }
}

@media screen and (min-width: 1280px) {
  h1 {
    line-height: 50px;
    font-size: 44px;
  }
}

h2{
    line-height: 48px;
    font-size: 40px;
    font-weight: 700;
}

h3{
    line-height: 34px;
    font-size: 28px;
    font-weight: 700;
}

h4{
    line-height: 32px;
    font-size: 23px;
    font-weight: 700;
}

h5{
    line-height: 24px;
    font-size: 19px;
    font-weight: 400;
}

h6{
    line-height: 24px;
    font-size: 16px;
    font-weight: 700;
    color: #3B3D3D;
}

.text-gray p {
  color: #e7e1f5;
}

.pygBgMen{
  background-color: #F5F2FA;
  background-image: url(assets/img/MainPage/Menavka-right.svg);
  background-repeat: no-repeat;
  background-position-x: calc(90% + 400px);
  background-position-y: -40px;
}

@media screen and (min-width: 640px) {
  .pygBgMen{
      background-image: url(assets/img/MainPage/Menavka-left.svg), url(assets/img/MainPage/Menavka-right.svg);
      background-position-x: -445px,calc(90% + 380px);
      background-position-y: 30px, -40px;
  }
}

@media screen and (min-width: 1024px) {
  .pygBgMen{
      background-position-x: -405px,calc(95% + 380px);
      background-position-y: 70px, -40px;
  }
}


/*  Blog article styles */

.pygmaliosBlog{
  max-width: 840px;
  font-style: italic;

  & p,h1,h2,h3,h4,h5,h6,blockquote,ol,ul{
    max-width: 640px;
    margin-left: auto;
    margin-right: auto;
    font-style: normal;
  }

  & h1,h2,h3,h4,h5,h6{
    margin-top: 60px;
  }

  & h5 + p{
    margin-top: 16px;
    margin-bottom: 40px;
  }

  & p{
    margin-top: 36px;
    margin-bottom: 36px;
  }

  & img, video{
    border-radius: 8px;
    margin-top: 36px;
    margin-bottom: 8px;
    width: 100%;
  }

  & iframe{
    border-radius: 8px;
    margin-top: 36px;
    margin-bottom: 8px;
    width: 100%;
    height: 45vmin;
    max-height: 500px;
  }

  & a{
    color: #8D61E1;
    text-decoration: underline;
  }

  & ol{
    list-style-position: outside;
    list-style-type: decimal;
  }

  & ul{
    list-style-position: outside;
    list-style-type: disc;
  }

  & li{
    margin-left: 20px;
  }

  & ul li::marker{
    color: #602BC6;
  }

  & ul p, & ol p{
    margin: 16px 0;
    width: auto;
  }

  & blockquote{
    & p::before{
      content: '\201c';
      padding-right: 2px;
    }

    & p::after{
      content: '\201d';
      padding-left: 2px;
    }
  }
}

.grecaptcha-badge {
  display: none !important;
}


// Shared buttons styles

.sb-outline{
  width: 100%;

  & .sb-button{
    width: 50px;
    height: 50px;
    margin-right: 20px;
    transition: transform 0.35s;
  }

  & .sb-button:hover{
    transform: translateY(-8px);
  }

  & .sb-wrapper{
    width: 100%;
    height: 100%;
    --sb-border-radius: 50% 50%;
    --sb-line-height: 1;
    --sb-color: var(--button-color);
    --sb-background: #fff;

    & .sb-content{
      border-radius: 50% 50%;

      & .sb-icon{
        box-shadow: 0 3px 5px -1px rgb(0 0 0 / 20%), 0 6px 10px 0 rgb(0 0 0 / 14%), 0 1px 18px 0 rgb(0 0 0 / 12%);
        border-radius: 50%;
      }
    }

    & svg{
      height: 35px;
    }
  }
}

@media screen and (max-width:640px) {
  .sb-outline.sb-button{
     width: 32px;
     margin-right: 25px;
  }
}


//Styles for swipper carousel (futureoftelco)

.swiper-wrapper{
  display: flex;
  padding-bottom: 20px;

  & .swiper-slide{
    height: auto;
  }
}

//Animations

.smooth-screen-right{
  visibility: visible !important;
  animation: contentOnScreenRight 0.7s;
}

.smooth-screen-left{
  visibility: visible !important;
  animation: contentOnScreenLeft 0.7s;
}

.visible-on-scroll1 {
  visibility: visible !important;
  animation: visibleOnScroll 0.6s;
}

.visible-on-scroll2 {
  visibility: visible !important;
  animation: visibleOnScroll 1.2s;
}

.visible-on-scroll3 {
  visibility: visible !important;
  animation: visibleOnScroll 1.8s;
}

.visible-on-scroll4 {
  visibility: visible !important;
  animation: visibleOnScroll 2.4s;
}

.hide-on-init{
  visibility: hidden;
}

@keyframes contentOnScreenRight {
  from {transform: translateX(100px);}
  to {transform: translateX(0px);}
}

@keyframes contentOnScreenLeft {
  from {transform: translateX(-100px);}
  to {transform: translateX(0px);}
}

@keyframes visibleOnScroll {
  from {
    opacity: 0;
    transform: translateY(100px);
  }
  to {
    opacity: 1;
    transform: translateY(0px);
  }
}
